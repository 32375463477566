html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.home-services-container ul:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.latest-news-container .latest-news {
  display: block;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden; }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.latest-news-container .promo-block .read-more {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem; }

.latest-news-container .promo-block {
  padding: 4.5rem 3rem 0 3rem;
  position: relative;
  border: 0;
  margin-bottom: 3rem; }
  .latest-news-container .promo-block:before {
    content: 'News';
    font-size: 0.6rem;
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    background: #31b0c3;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0; }
  .latest-news-container .promo-block:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    border: 1px solid rgba(49, 176, 195, 0.2); }
  .latest-news-container .promo-block .title {
    color: #111;
    font-weight: 700;
    display: block;
    margin-bottom: 0.75rem;
    font-size: 2.3rem; }
  .latest-news-container .promo-block p {
    margin-bottom: 1.5rem; }

.centre h1 {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #111;
  text-align: center;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;
  position: relative; }
  .centre h1:before, .centre h1:after {
    content: '';
    display: block;
    bottom: 0;
    height: 1px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centre h1:before {
    width: 260px;
    border-bottom: 1px solid #adc331; }
  .centre h1:after {
    width: 130px;
    border-bottom: 1px solid #111; }

.hero {
  background: url("/images/banner/banner-home.jpg") no-repeat center center;
  background-size: cover; }
  @media only screen and (min-width: 50em) {
    .hero {
      height: 550px; }
      .hero .home-services {
        height: 550px; } }
  @media only screen and (min-width: 60em) {
    .hero {
      height: 700px; }
      .hero .home-services {
        height: 700px; } }

.tagline-container {
  width: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 3rem;
  margin-bottom: 0;
  display: none; }
  .tagline-container .tagline {
    font-size: 2.3rem;
    width: 50%; }
    @media only screen and (min-width: 60em) {
      .tagline-container .tagline {
        font-size: 3rem;
        width: 40%; } }
  @media only screen and (min-width: 50em) {
    .tagline-container {
      display: block; } }

.home-services-container {
  display: none; }
  .home-services-container .home-services {
    position: relative; }
    .home-services-container .home-services .block {
      margin-bottom: 0; }
  .home-services-container ul {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    bottom: 0; }
  .home-services-container li {
    height: 140px;
    position: relative;
    float: left;
    width: 33.33333%; }
    .home-services-container li:nth-child(odd) a {
      background: rgba(17, 17, 17, 0.5); }
      .home-services-container li:nth-child(odd) a:hover {
        background: rgba(17, 17, 17, 0.7); }
    .home-services-container li:nth-child(even) a {
      background: rgba(17, 17, 17, 0.7); }
      .home-services-container li:nth-child(even) a:hover {
        background: rgba(17, 17, 17, 0.9); }
    .home-services-container li a {
      display: block;
      width: 100%;
      height: 100%; }
    .home-services-container li span {
      border-top: 1px #adc331 solid;
      border-bottom: 1px #31b0c3 solid;
      padding: 0.75rem 0;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  @media only screen and (min-width: 50em) {
    .home-services-container {
      display: block; } }
  @media only screen and (min-width: 60em) {
    .home-services-container li {
      height: 170px; }
    .home-services-container a {
      font-size: 0.9rem; } }

.home-intro-slider {
  margin-bottom: 0; }
  .home-intro-slider ul {
    text-align: center; }
    .home-intro-slider ul li {
      font-size: 1.1rem;
      line-height: 1.7; }
      @media only screen and (min-width: 50em) {
        .home-intro-slider ul li {
          font-size: 1.5rem; } }
  .home-intro-slider .bx-viewport {
    margin-bottom: 3rem; }
  .home-intro-slider .bx-controls {
    text-align: center; }
    .home-intro-slider .bx-controls .bx-pager-item {
      display: inline-block;
      margin: 0 5px; }
    .home-intro-slider .bx-controls a {
      height: 8px;
      width: 12px;
      background: #e0e0e0;
      display: inline-block;
      text-indent: -9999px; }
      .home-intro-slider .bx-controls a.active {
        background: #31b0c3; }

.team-form-container {
  background: #e0e0e0;
  border-bottom: 5px solid #adc331; }
  .team-form-container .team-form {
    position: relative;
    padding: 0; }
  .team-form-container .team-container {
    background: url("/images/banner/banner-team.jpg") -100px center no-repeat;
    background-size: cover;
    min-height: 485px;
    position: relative; }
    .team-form-container .team-container:before {
      content: 'Minesh Patel - DIRECTOR';
      color: #fff;
      font-weight: 700;
      background: #111;
      padding: 0.75rem 3rem;
      position: absolute;
      bottom: 0;
      height: 50px; }
    @media only screen and (min-width: 50em) {
      .team-form-container .team-container:before {
        left: 4.5rem; }
      .team-form-container .team-container:after {
        content: '';
        height: 50px;
        background: rgba(17, 17, 17, 0.4);
        padding: 0.75rem 3rem;
        position: absolute;
        bottom: 0;
        left: 0; } }
    @media only screen and (min-width: 60em) {
      .team-form-container .team-container {
        background: url("/images/banner/banner-team.jpg") left center no-repeat; } }
  .team-form-container .block-form {
    background: #8b8b8b;
    box-shadow: none;
    margin-bottom: 0; }
    .team-form-container .block-form .title {
      font-size: 2.3rem; }
    .team-form-container .block-form .title,
    .team-form-container .block-form .form-intro {
      color: #fff; }
  @media only screen and (min-width: 50em) {
    .team-form-container .form-container {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: #8b8b8b;
      margin-bottom: 0; } }

.latest-news-container {
  padding: 3rem 0 0 0; }
  .latest-news-container .promo-block:first-child:before {
    content: 'Promo'; }
  @media only screen and (min-width: 50em) {
    .latest-news-container .promo-block {
      float: left; }
      .latest-news-container .promo-block:nth-child(n) {
        clear: none;
        width: 50%; }
      .latest-news-container .promo-block:nth-child(2n+1) {
        clear: both;
        margin-left: 0; } }
  .latest-news-container .promo-block .read-more {
    color: #fff;
    display: inline-block;
    background-color: #31b0c3;
    padding: 0.75rem 1.5rem; }
    .latest-news-container .promo-block .read-more:hover {
      background-color: #adc331; }
  @media only screen and (min-width: 50em) {
    .latest-news-container {
      padding: 6rem 0; }
      .latest-news-container .latest-news .news {
        margin-bottom: 0;
        float: left; }
        .latest-news-container .latest-news .news:nth-child(n) {
          clear: none;
          width: 50%; }
        .latest-news-container .latest-news .news:nth-child(2n+1) {
          clear: both;
          margin-left: 0; } }
