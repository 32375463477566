
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

.centre {

    h1 {

    	text-transform: uppercase;
        font-size: $font-size-body - 0.2;
        color: $color-black;
        text-align: center;
        padding-bottom: $block-padding;
        margin-bottom: $block-margin;
        position: relative;

        &:before,
        &:after {

        	content: '';
        	display: block;
        	bottom: 0;
        	height: 1px;
        	@include centreX();
        }

        &:before {
        	width: 260px;
        	border-bottom: 1px solid $color-primary;
        }

        &:after {
        	width: 130px;
        	border-bottom: 1px solid $color-black;
        }

    }
}


.hero {

    background: url('/images/banner/banner-home.jpg') no-repeat center center;
    background-size: cover;

    @media #{$tablet} {

        height: 550px;

        .home-services {

             height: 550px;
        }
    }


    @media #{$tabletXL} {

        height: 700px;

        .home-services {

             height: 700px;
        }
    }
}

.tagline-container {

    width: 80%;
    @include centreX();
    padding: $block-margin;
    margin-bottom: 0;
    display: none;

    .tagline {

        font-size: $h1;
        width: 50%;

         @media #{$tabletXL} {

            font-size: $h1 + 0.7;
            width: 40%;
        }

    }

    @media #{$tablet} {

        display: block;
    }
}

.home-services-container {

    display: none;

    .home-services {

         position: relative;

         .block {

            margin-bottom: 0;
         }
    }

    ul {

        @extend %clear-fix;
        @include centreX();
        width: 80%;
        bottom: 0;
    }

    li {

        height: 140px;
        position: relative;

        &:nth-child(odd){

            a {
                background: rgba($color-black, 0.5);

                &:hover {
                    background: rgba($color-black, 0.7);
                }
            }

        }

        &:nth-child(even){
            a {
                background: rgba($color-black, 0.7);

                &:hover {
                    background: rgba($color-black, 0.9);
                }
            }
        }

        @include fraction((

            grin: true,
            columns: 3,
            ie-fix: true

        ))

        a {
            display: block;
            width: 100%;
            height: 100%;

        }

       span {
            border-top: 1px $color-primary solid;
            border-bottom: 1px $color-secondary solid;
            padding: $block-padding/2 0;
            color: $color-white;
            text-align: center;
            text-transform: uppercase;
            font-weight: $font-bold;
            font-size: $font-size-body - 0.3rem;
            @include centre();
        }
    }

    @media #{$tablet} {

       display: block;
    }

    @media #{$tabletXL} {

        li {

            height: 170px
        }

        a {

            font-size: $font-size-body - 0.2rem;

        }

    }
}

.home-intro-slider {

    margin-bottom: 0;

	ul {

		text-align: center;

		li {
			font-size: $h6;
            line-height: 1.7;

             @media #{$tablet} {

                font-size: $h4;

            }
		}
	}

    .bx-viewport {

        margin-bottom: $block-margin;

    }

    .bx-controls {

        text-align: center;

        .bx-pager-item {

            display: inline-block;
            margin: 0 5px;
        }

        a {

            height: 8px;
            width: 12px;
            background: $color-lightgrey;
            display: inline-block;
            text-indent: -9999px;

            &.active {

                background: $color-secondary;
            }
        }
    }

}


.team-form-container {

    background: $color-lightgrey;

    border-bottom: 5px solid $color-primary;
    // min-height: 460px;

    .team-form {
        position: relative;
        padding: 0;
    }

    .team-container {

        background: url('/images/banner/banner-team.jpg') -100px center no-repeat;
        background-size: cover;
        min-height: 485px;
        position: relative;

        &:before {

            content: 'Minesh Patel - DIRECTOR';
            color: $color-white;
            font-weight: $font-bold;
            background: $color-black;
            padding: $block-padding/2 $block-margin;
            position: absolute;
            bottom: 0;
            // left: $block-margin * 1.5;
            height: 50px

        }

        @media #{$tablet} {

             // background: url('/images/banner/banner-team.jpg') left center no-repeat;

             &:before {

                left: $block-margin * 1.5;

            }

             &:after {

                content:'';
                height: 50px;
                background: rgba($color-black, 0.4);
                padding: $block-padding/2 $block-margin;
                position: absolute;
                bottom: 0;
                left: 0;

            }
        }

        @media #{$tabletXL} {

             background: url('/images/banner/banner-team.jpg') left center no-repeat;
        }
    }

    .block-form {

        background: #8b8b8b;
        box-shadow: none;
        margin-bottom: 0;

        .title {

            font-size: $h1;
        }

        .title,
        .form-intro {
            color: $color-white;
        }
    }

    @media #{$tablet} {

       .form-container {

            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: #8b8b8b;
            margin-bottom: 0;
        }
    }
}

.latest-news-container {

    padding: $block-margin 0 0 0;


    .latest-news {

         @extend %elastic;
    }

    .promo-block {

        @extend %style-news;

        &:first-child {

            &:before {

                content: 'Promo';

            }

        }


         @media #{$tablet} {

            @include fraction ((

                grid: true,
                columns: 2,
                ie-fix: true
            ))
        }

        .read-more {

            color: $color-white;
            display: inline-block;
            background-color: $color-secondary;
            padding: $block-padding/2 $block-padding;
            @extend %uppercase;

            &:hover {
                background-color: $color-primary;
            }
        }
    }

    // .latest-news {

    //      @extend %style-news;

    //     .news-items {

    //         @extend %elastic;
    //         margin-bottom: 0;
    //     }

    //     .news {

    //         @media #{$tablet} {

    //             @include fraction ((

    //                 grid: true,
    //                 columns: 2,
    //                 ie-fix: true
    //             ))
    //         }

    //     }
    // }


     @media #{$tablet} {

        padding: $block-margin * 2 0;

        .latest-news {

            .news {

                margin-bottom: 0;

                @include fraction ((

                    grid: true,
                    columns: 2,
                    ie-fix: true
                ))
            }
        }

    }

}
